




































import { DataResponseListMasterAssetNotRent } from "@/models/interface/assets.interface";
import { CustomAddressDataList } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListProduct } from "@/models/interface/logistic.interface";
import { EnumStatus } from "@/models/interface/salesOrder.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { logisticServices } from "@/services/logistic.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import Vue, { PropType } from "vue";
import {
  DataRent,
  DetailStatus,
  FooterCreateInternalContract,
} from "./CreateInternalContract.vue";
import { Decimal } from "decimal.js-light";

export interface CustomDataSourceProductServiceTab {
  optionCustom?: any;
  key: number;
  productId: string;
  productName: string;
  productCode: string;
  qty: number | string;
  uom: string;
  rentPrice: number | string;
  subTotal: string;
  id: string;
  type: string;
}

export default Vue.extend({
  props: {
    dataRent: {
      type: Object as PropType<DataRent>,
    },
    detail: {
      type: Object as PropType<DetailStatus>,
    },
    detailDataSourceProductServices: {
      type: Array as PropType<CustomDataSourceProductServiceTab[]>,
    },
    rentType: String,
    detailFooterTotalProductService: String,
    view: Boolean,
  },
  watch: {
    "dataRent.rentPeriod": {
      immediate: true,
      deep: true,
      handler() {
        this.handleCountingSubTotalProductService();
      },
    },
    footerCreateInternalContract: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit(
          "set-total-productServices",
          this.footerCreateInternalContract.total
        );
      },
    },
    dataSource: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("set-dataSource-productServices", this.dataSource);
      },
    },
    deleteLineId: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("set-delete-productServices", this.deleteLineId);
      },
    },
    rentType: {
      immediate: true,
      deep: true,
      handler() {
        switch (this.rentType) {
          case "Month":
            this.columnsTable[4].title = "Rent Price / Month";
            break;
          case "Day":
            this.columnsTable[4].title = "Rent Price / Day";
            break;
        }
        this.columnsTable = this.columnsTable.slice();
      },
    },
    detailDataSourceProductServices: {
      immediate: true,
      deep: true,
      handler() {
        this.assignDataFirstLoadDetail();
      },
    },
    detailFooterTotalProductService: {
      immediate: true,
      deep: true,
      handler() {
        this.assignDataFirstLoadDetail();
      },
    },
    view: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.view) {
          this.dataSource = this.dataSource.map(dataMap => {
            return {
              ...dataMap,
              disabledSelect:
                this.isActualNew ||
                this.isActualDraft ||
                this.isActualNeedApproval ||
                this.isRevised
                  ? false
                  : true,
              disabledInput:
                this.isActualNew ||
                this.isActualDraft ||
                this.isActualNeedApproval ||
                this.isRevised
                  ? false
                  : true,
            };
          });
        }
      },
    },
  },
  data() {
    return {
      firstLoad: false as boolean,
      footerCreateInternalContract: {
        total: 0,
      } as FooterCreateInternalContract,
      loadingTable: false as boolean,
      selectedRowKeys: [] as number[],
      dataSource: [] as CustomDataSourceProductServiceTab[],
      deleteLineId: [] as string[],
      dataDefaultProduct: [] as DataListProduct[],
      columnsTable: [
        {
          title: "Part Number",
          dataIndex: "productCode",
          key: "productCode",
          width: 200,
          scopedSlots: { customRender: "productCode" },
          responsiveColSelect: [
            {
              name: "productCode",
              placeholder: "Type for more data",
              style: "width: 100%;",
              disabled: false,
              value: "name",
              options: [] as DataResponseListMasterAssetNotRent[],
              loading: false,
            },
            {
              name: "productName",
              placeholder: "Type for more data",
              style: "width: 100%;",
              disabled: false,
              value: "name",
              options: [] as CustomAddressDataList[],
              loading: false,
            },
          ],
          responsiveColInput: [
            {
              name: "qty",
              placeholder: "Qty Adjustment",
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "rentPrice",
              placeholder: "Rent Price",
              style: "width: 100%",
              disabled: false,
            },
          ],
        },
        {
          title: "Part Name",
          dataIndex: "productName",
          key: "productName",
          width: 200,
          scopedSlots: { customRender: "productName" },
        },
        {
          title: "Qty",
          dataIndex: "qty",
          key: "qty",
          width: 150,
          scopedSlots: { customRender: "qty" },
        },
        {
          title: "UOM",
          dataIndex: "uom",
          key: "uom",
          width: 150,
          scopedSlots: { customRender: "uom" },
        },
        {
          title: "Rent Price / Month",
          dataIndex: "rentPrice",
          key: "rentPrice",
          width: 250,
          scopedSlots: { customRender: "rentPrice" },
          align: "center",
        },
        {
          title: "SUB Total",
          dataIndex: "subTotal",
          key: "subTotal",
          width: 150,
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          width: "120px",
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: ["delete"],
        },
      ] as ColumnTableCustom[],
    };
  },
  methods: {
    assignDataFirstLoadDetail() {
      if (
        this.detailDataSourceProductServices &&
        this.detailDataSourceProductServices.length > 0 &&
        !this.firstLoad &&
        this.detailFooterTotalProductService
      ) {
        this.dataSource = this.detailDataSourceProductServices.slice();
        this.footerCreateInternalContract.total =
          this.detailFooterTotalProductService;
        this.firstLoad = true;
      }
    },
    mappingSubTotal(dataMap) {
      return {
        ...dataMap,
        subTotal: currencyFormat(
          new Decimal(changeCurrencytoNumeric(dataMap.qty) || 0)
            .times(new Decimal(changeCurrencytoNumeric(dataMap.rentPrice) || 0))
            .times(this.dataRent.rentPeriod || 0)
        ),
      };
    },
    handleCountingSubTotalProductService() {
      this.dataSource = this.dataSource.map(dataMap => {
        return this.mappingSubTotal(dataMap);
      });
      const sumSubTotal = this.dataSource.reduce((current, dataReduce) => {
        return new Decimal(changeCurrencytoNumeric(dataReduce.subTotal))
          .plus(current)
          .toNumber();
      }, 0);
      this.footerCreateInternalContract.total = currencyFormat(
        sumSubTotal || 0
      );
    },
    handleAddRow() {
      this.dataSource = [
        ...this.dataSource,
        {
          type: "",
          productId: "",
          productName: "",
          productCode: "",
          key: this.dataSource.length,
          qty: "1",
          uom: "PCE",
          rentPrice: "0",
          subTotal: "",
          id: "",
          optionCustom: [
            {
              name: "productCode",
              option: this.dataDefaultProduct.map(dataMap => {
                return {
                  ...dataMap,
                  customCode: dataMap.code,
                  customName: dataMap.name,
                  name: dataMap.code,
                };
              }),
            },
            {
              name: "productName",
              option: this.dataDefaultProduct.map(dataMap => {
                return {
                  ...dataMap,
                  customCode: dataMap.code,
                  customName: dataMap.name,
                };
              }),
            },
          ],
        },
      ];
    },
    onSelectChange(selectedRowKeys: number[]) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("onSelectChange", this.selectedRowKeys);
    },
    handleSearchSelectTable(value, key, colName) {
      switch (colName) {
        case "productCode":
          this.getProduct(value, false, key, colName);
          break;
        case "productName":
          this.getProduct(value, false, key, colName);
          break;
        // tinggal kasih case klo ada column baru
        default:
          break;
      }
    },
    handleInput(value, key, objectColInput, objectColName, onEvent): void {
      if (onEvent === "onFocus") {
        if (objectColName === "qty") {
          this.dataSource[key].qty = changeCurrencytoNumeric(value);
          this.dataSource[key].rentPrice = changeCurrencytoNumeric(
            this.dataSource[key].rentPrice
          );
        } else if (objectColName === "rentPrice") {
          this.dataSource[key].rentPrice = changeCurrencytoNumeric(value);
          this.dataSource[key].qty = changeCurrencytoNumeric(
            this.dataSource[key].qty
          );
        }
      }

      if (onEvent === "onChange") {
        this.dataSource[key][objectColInput.name] = value;
        this.dataSource = this.dataSource.slice();
      }
      if (onEvent === "onBlur") {
        this.dataSource[key].subTotal = currencyFormat(
          new Decimal(this.dataSource[key].qty || 0)
            .times(this.dataSource[key].rentPrice || 0)
            .times(this.dataRent.rentPeriod || 0)
            .toNumber()
        );
        if (!isNaN(Number(this.dataSource[key].subTotal))) {
          this.dataSource[key].subTotal = currencyFormat(
            this.dataSource[key].subTotal
          );
        }
        let sumSubTotal = 0;
        this.dataSource.forEach(dataForeach => {
          sumSubTotal += changeCurrencytoNumeric(dataForeach.subTotal);
        });
        this.footerCreateInternalContract.total =
          currencyFormat(sumSubTotal) || 0;
        this.dataSource[key].qty = currencyFormat(this.dataSource[key].qty);
        if (typeof this.dataSource[key].rentPrice === "string") {
          this.dataSource[key].rentPrice = changeCurrencytoNumeric(
            this.dataSource[key].rentPrice
          );
        }
        this.dataSource[key].rentPrice = currencyFormat(
          this.dataSource[key].rentPrice
        );
        this.dataSource = this.dataSource.slice();
      }
    },
    handleSelect(value, key, col) {
      switch (col) {
        case "productCode":
          if (value) {
            const dataFind = this.dataSource[key].optionCustom[0].option.find(
              findData => findData.name === value
            );
            this.dataSource[key].productId = dataFind.id;
            this.dataSource[key].productCode = dataFind.customCode || "";
            this.dataSource[key].productName = dataFind.customName || "";
            this.dataSource[key].type = dataFind.type || "";
            this.getDetailProduct(dataFind.id, key);
          } else {
            this.dataSource[key].productCode = value;
            this.dataSource[key].productId = "";
            this.dataSource[key].productName = "";
            this.dataSource[key].type = "";
          }
          break;
        case "productName":
          if (value) {
            const dataFind = this.dataSource[key].optionCustom[1].option.find(
              findData => findData.name === value
            );
            this.dataSource[key].productId = dataFind.id;
            this.dataSource[key].productCode = dataFind.customCode || "";
            this.dataSource[key].productName = dataFind.customName || "";
            this.dataSource[key].type = dataFind.type || "";
            this.getDetailProduct(dataFind.id, key);
          } else {
            this.dataSource[key].productName = value;
            this.dataSource[key].productId = "";
            this.dataSource[key].productCode = "";
            this.dataSource[key].type = "";
          }
          break;
      }
    },
    responseDeleteTable(response) {
      this.dataSource = this.dataSource.filter((data, index) => {
        if (response.data.key === index && data.id)
          this.deleteLineId.push(data.id);
        return index !== response.data.key;
      });
      this.dataSource.forEach((data, index) => (data.key = index));
      this.dataSource = this.dataSource.slice();
      this.handleCountingSubTotalProductService();
    },
    getDetailProduct(idProduct, keyDataSource) {
      logisticServices.getDetailProduct(idProduct).then(response => {
        this.dataSource[keyDataSource].uom = response.baseUnit;
      });
    },
    getProduct(valueSearch, defaultValue, key?, columnName?) {
      const params: RequestQueryParamsModel = {
        search: "type~SERVICE",
      };
      switch (columnName) {
        case "productName":
          if (valueSearch)
            params.search = `name~*${valueSearch}*_AND_type~SERVICE`;
          break;
        case "productCode":
          if (valueSearch)
            params.search = `code~*${valueSearch}*_AND_type~SERVICE`;
          break;
      }
      logisticServices.listProduct(params).then(response => {
        if (defaultValue) {
          this.dataDefaultProduct = response.data;
        } else {
          this.dataSource[key].optionCustom[1].option = response.data.map(
            dataMap => {
              return {
                ...dataMap,
                customCode: dataMap.code,
                customName: dataMap.name,
              };
            }
          );
          this.dataSource[key].optionCustom[0].option = response.data.map(
            dataMap => {
              return {
                ...dataMap,
                customCode: dataMap.code,
                customName: dataMap.name,
                name: dataMap.code,
              };
            }
          );
        }
      });
    },
  },
  created() {
    this.getProduct("", true);
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      };
    },
    // status can change
    isNew(): boolean {
      return this.detail.status === EnumStatus.New;
    },
    isDraft(): boolean {
      return this.detail.status === EnumStatus.Draft;
    },
    isNeedApproval(): boolean {
      return this.detail.status === EnumStatus.NeedApproval;
    },
    isRejected(): boolean {
      return this.detail.status === EnumStatus.Rejected;
    },
    isRevised(): boolean {
      return this.detail.status === EnumStatus.Revised;
    },
    isEnded(): boolean {
      return this.detail.status === EnumStatus.Ended;
    },
    isApproved(): boolean {
      return this.detail.status === EnumStatus.Approved;
    },
    isPartialEnded(): boolean {
      return this.detail.status === EnumStatus.PartialEnded;
    },
    isChangeUnit(): boolean {
      return this.detail.status === EnumStatus.ChangeUnit;
    },
    isChangeLocation(): boolean {
      return this.detail.status === EnumStatus.ChangeLocation;
    },
    isAcceptanceOfOrder(): boolean {
      return this.detail.isAcceptanceOfOrder;
    },
    // status can't change actual in document
    isActualNew(): boolean {
      return this.detail.actualStatusDocument === EnumStatus.New;
    },
    isActualDraft(): boolean {
      return this.detail.actualStatusDocument === EnumStatus.Draft;
    },
    isActualNeedApproval(): boolean {
      return this.detail.actualStatusDocument === EnumStatus.NeedApproval;
    },
  },
});
