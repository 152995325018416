var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "a-button",
            {
              staticStyle: { margin: "10px 0" },
              attrs: { type: "primary", disabled: _vm.view },
              on: { click: _vm.handleAddRow }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c("TableCustom", {
            attrs: {
              dataSource: _vm.dataSource,
              columns: _vm.columnsTable,
              scroll: { x: "calc(700px + 50%)", y: 500 },
              paginationcustom: false,
              defaultPagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                },
                showSizeChanger: true
              },
              loading: _vm.loadingTable,
              handleSelect: _vm.handleSelect,
              handleInput: _vm.handleInput,
              handleSearchSelectTable: _vm.handleSearchSelectTable,
              footerCreateInternalContract: _vm.footerCreateInternalContract,
              onSelectChange: _vm.onSelectChange,
              selectedRowKeys: _vm.selectedRowKeys
            },
            on: { "on-delete": _vm.responseDeleteTable }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }